import { MouseEventHandler, FunctionComponent } from 'react';
import Close from 'dibs-icons/exports/legacy/Close';
import classnames from 'classnames';
import { defineMessages, useIntl } from 'dibs-react-intl';

import styles from './main.scss';

type ModalCloseButtonProps = {
    dataTn?: string;
    onClick: MouseEventHandler;
    className?: string;
    disabled?: boolean;
    backgroundColor?: string;
};

const messages = defineMessages({
    label: {
        id: 'modal.ModalCloseButton.label',
        defaultMessage: 'Close',
    },
});
export const ModalCloseButton: FunctionComponent<ModalCloseButtonProps> = props => {
    const { className, dataTn, onClick, disabled, backgroundColor = 'transparent' } = props;
    const intl = useIntl();
    let bgColorClass = backgroundColor;
    if (backgroundColor === 'translucentWhite') {
        bgColorClass = styles.translucentWhite;
    }

    const wrapperClass = classnames(styles.closeButton, className, {
        [styles.closeButtonDisabled]: disabled,
        [bgColorClass]: !!backgroundColor,
    });

    return (
        <button
            aria-label={intl.formatMessage(messages.label)}
            data-tn={dataTn || 'modal-close-button'}
            className={wrapperClass}
            onClick={disabled ? () => {} : onClick}
        >
            <Close />
        </button>
    );
};
