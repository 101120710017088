import elementClass from 'element-class';

type classDomNodeArgs = {
    domNode: HTMLElement | null;
    className: string;
};

export function addClassToNode({ domNode, className }: classDomNodeArgs): void {
    if (domNode && !elementClass(domNode).has(className)) {
        elementClass(domNode).add(className);
    }
}

export function removeClassFromNode({ domNode, className }: classDomNodeArgs): void {
    /* istanbul ignore else */
    if (domNode && elementClass(domNode).has(className)) {
        elementClass(domNode).remove(className);
    }
}
