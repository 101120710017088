import { createContext } from 'react';

// DO NOT EXPORT TO CONSUMERS OUTSIDE OF DIBS-ELEMENTS

// https://fettblog.eu/typescript-react/context/

export type ModalContextProps = {
    current: HTMLDivElement | null;
};

export const ModalRefContext = createContext<Partial<ModalContextProps>>({});
